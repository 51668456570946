import { findLast } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  ActivityDetectorProvider,
  FeatureFlagsProvider,
} from '@fdha/common-hooks';
import {
  Loader,
  LeftNav,
  LeftNavTab,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import { Box, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  PlatformType,
  UserSessionInfoInput,
  useUpdateUserSessionInfoMutation,
} from '@fdha/graphql-api-foodops';

import { Public, Private } from './routes';

function App() {
  const authStatus = useAuthStatus();
  const client = useApolloClient();
  const { closeDialog } = useDialog();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const [updateUserSessionInfo] = useUpdateUserSessionInfoMutation();

  const onSignOut = useCallback(async () => {
    showSnackbar({
      severity: 'warning',
      message: 'Session expired. Please log in again.',
      closeOnClickOutside: true,
      closeAutomatically: false,
    });
    closeDialog();
    navigate('/', { replace: true });
  }, [showSnackbar, closeDialog, navigate]);

  useEffect(() => {
    const clearCache = async () => {
      try {
        await client.cache.reset();
      } catch (e) {
        console.log('Error cleaning cache', e);
      }
    };

    const updateSessionInfo = async (props: UserSessionInfoInput) => {
      await updateUserSessionInfo({
        variables: {
          props,
        },
      });
    };

    if (authStatus === AuthStatus.SIGNED_OUT) {
      clearCache();
    }

    if (authStatus === AuthStatus.SIGNED_IN) {
      updateSessionInfo({
        platform: PlatformType.Web,
        action: 'activateSession',
      });
    }
  }, [authStatus, client, updateUserSessionInfo]);

  const tabs: LeftNavTab[] = [
    {
      id: 'tab-home',
      label: 'Home',
      'aria-controls': 'tabpanel-tab-home',
      activeIcon: 'home',
      inactiveIcon: 'home-outline',
      to: '/',
      component: Link,
      'data-testId': 'TAB_HOME',
    },
    {
      id: 'tab-create',
      label: 'Create',
      'aria-controls': 'tabpanel-tab-create',
      activeIcon: 'plus-circle',
      inactiveIcon: 'plus-circle-outline',
      to: '/create',
      component: Link,
      'data-testId': 'TAB_CREATE',
    },
  ];

  const selectedTabId =
    findLast(
      tabs,
      (tab) => location.pathname.substr(0, tab.to.length) === tab.to
    )?.id || 'tab-home';

  if (authStatus === AuthStatus.LOADING) {
    return <Loader />;
  }

  return authStatus === AuthStatus.SIGNED_IN ? (
    <Box bgcolor={theme.palette.primary.main} display="flex" height="100vh">
      <LeftNav
        tabs={tabs}
        activeTabId={selectedTabId}
        logo="/images/Faeth_Icon_Large_White.png"
      />
      <Box
        aria-labelledby={selectedTabId}
        bgcolor={theme.palette.background.default}
        borderRadius="15px 0px 0px 15px"
        id={`tabpanel-${selectedTabId}`}
        role="tabpanel"
        width="100%"
        overflow="auto"
      >
        <FeatureFlagsProvider>
          <ActivityDetectorProvider onSignOut={onSignOut}>
            <Private />
          </ActivityDetectorProvider>
        </FeatureFlagsProvider>
      </Box>
    </Box>
  ) : (
    <Public />
  );
}

export default App;
